<template>
  <div class="row">
    <div class="col-md-8 ml-auto mr-auto">
      <card class="card-subcategories card-plain">
        <template slot="header">
          <h4 class="card-title text-center mt-5">Agency Details</h4>
          <br />
        </template>

        <tabs type="primary" tabContentClasses="tab-subcategories" square centered class="row">
          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-badge"></i>Account Details
            </span>
            <ValidationObserver ref="form">
              <form @submit.prevent="addAgencyDetails">
                <div>
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-6">
                      <label>First Name</label>
                      <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="firstName" placeholder="First Name"
                          addon-left-icon="tim-icons icon-single-02" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Middle Name</label>
                      <ValidationProvider name="middleName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="middleName" placeholder="Middle Name"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Last Name</label>
                      <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="lastName" placeholder="Last Name"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Agency Name</label>
                      <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="agencyName" placeholder="Agency Name"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Email</label>
                      <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                        <base-input disabled required v-model="email" placeholder="Email"
                          addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Phone</label>
                      <ValidationProvider name="phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="phone" placeholder="Phone(Not shown to public)"
                          addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-10">
                      <label>Display Picture (Shown on Home Page)</label>
                      <image-upload @change="onImageChange" select-text="Select Image" :src="profilePath"
                        style="width: 100% ;" />
                    </div>
                  </div>
                  <div class="text-center">
                    <base-button native-type="submit" type="primary">Save</base-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </tab-pane>

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-bag-16"></i>Address
            </span>
            <ValidationObserver ref="form">
              <form @submit.prevent="addLocationDetails">
                <div>
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-6">
                      <label>Country</label>
                      <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="country" placeholder="Country"
                          addon-left-icon="tim-icons icon-single-02" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>City</label>
                      <ValidationProvider name="middleName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="city" placeholder="City"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6 text-left">
                      <label>Street Name</label>
                      <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="streetName" placeholder="Street Name"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="text-right">
                      <base-button native-type="submit" type="primary">Save</base-button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </tab-pane>

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-money-coins "></i>Payment Details
            </span>
            <ValidationObserver ref="form">
              <form @submit.prevent="addPaymentDetails">
                <div>
                  <div class="row justify-content-center lg-5">
                    <div class="col-md-12">
                      <label>Phone</label>
                      <ValidationProvider name="phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="phone" placeholder="Phone(Not shown to public)"
                          addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="text-center">
                      <base-button native-type="submit" type="primary">Update Payment Details</base-button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>
</template>
<script>
import { extend, validate } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { api_url, datumMixin } from '../../../env';
import {
  ImageUpload,

} from 'src/components/index';

extend("email", email);
extend("required", required);
extend("numeric", numeric);

export default {
  mixins: [datumMixin],
  components: {
    ImageUpload,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      middleName: '',
      phone: '',
      email: '',
      agencyName: '',
      profilePath: '',
      country: '',
      city: '',
      streetName:''

    };
  },

  methods: {
    getAgencyDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/user', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.user = response.user[0]
              this.firstName = response.user["first_name"]
              this.middleName = response.user["middle_name"]
              this.lastName = response.user["last_name"]
              this.email = response.user["email"]
              this.agencyName = response.user["agency_name"]
              let agencyDetails = JSON.parse(response.user.agency_details)
              this.phone = agencyDetails.mobile
              this.profilePath = agencyDetails.profile_path
              let locationDetails = JSON.parse(response.user.location_details)
              this.city = locationDetails.city
              this.country = locationDetails.country
              this.streetName = locationDetails.street_name

            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    addAgencyDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Content-Type": "multipart/form-data",

      };
      var formdata = new FormData();
      formdata.append("first_name", this.firstName);
      formdata.append("last_name", this.lastName);
      formdata.append("middle_name", this.middleName);
      formdata.append("dob", this.dob);
      formdata.append("email", this.email);
      formdata.append("agency_name", this.agencyName);
      formdata.append("profile_image", this.profilePath);
      formdata.append("mobile", this.phone);


      this.$http.post(api_url + '/agent/addAccountDetails', formdata, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              this.alertNotification("top", "right", "success", response.message)
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    addLocationDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Content-Type": "multipart/form-data",

      };
      var formdata = new FormData();
      formdata.append("street_name", this.streetName);
      formdata.append("city", this.city);
      formdata.append("country", this.country);
   
      this.$http.post(api_url + '/agent/addLocationDetails', formdata, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              this.alertNotification("top", "right", "success", response.message)
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    addPaymentDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Content-Type": "multipart/form-data",

      };
      var formdata = new FormData();
      formdata.append("phone_number", this.phone);

      this.$http.post(api_url + '/agent/addPaymentDetails', formdata, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              this.alertNotification("top", "right", "success", response.message)
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    onImageChange(file) {
      this.profilePath = file;
    },



  },
  created() {
    this.getAgencyDetails();
  }
};
</script>
<style lang="scss">
.tim-icons {
  color: white;
}
</style>