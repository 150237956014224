<template>
    <div class="row">
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

        <!-- stories -->
        <Story></Story>
        <div class="col-md-6 ml-auto mr-auto">

            <div v-for="post in  posts">
                <div v-if="post.media_type == 1">
                    <card class="mb-3">

                        <img slot="image" class="card-img-top" :src="post.path" alt="Card image cap">
                        <span class="card-text">{{ post.caption }}</span>


                        <div class="d-flex justify-content-between align-items-center">
                            <div class="p-2">
                                <div class="d-flex justify-content-between align-items-center">

                                    <div class="p-2">

                                        <i @click="likeAndUnlikePosts(post.id, 'remove_like', post)" v-if="post.isLiked">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35"
                                                viewBox="0 0 26 26" fill="#aa39f1">
                                                <path
                                                    d="M12 21.35l-1.45-1.32C5.4 16.07 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 7.57-8.55 11.54L12 21.35z" />
                                            </svg>
                                        </i>


                                        <i @click="likeAndUnlikePosts(post.id, 'like', post)" v-else>
                                            <svg viewBox="0 0 32 32" width="30" height="30"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16 28.72a3 3 0 0 1-2.13-.88l-10.3-10.3a8.72 8.72 0 0 1-2.52-6.25 8.06 8.06 0 0 1 8.14-8A8.06 8.06 0 0 1 15 5.68l1 1 .82-.82a8.39 8.39 0 0 1 11-.89 8.25 8.25 0 0 1 .81 12.36l-10.5 10.51a3 3 0 0 1-2.13.88ZM9.15 5.28A6.12 6.12 0 0 0 4.89 7a6 6 0 0 0-1.84 4.33A6.72 6.72 0 0 0 5 16.13l10.3 10.3a1 1 0 0 0 1.42 0l10.51-10.52a6.25 6.25 0 0 0 1.77-4.8 6.18 6.18 0 0 0-2.43-4.55 6.37 6.37 0 0 0-8.37.71L16.71 8.8a1 1 0 0 1-1.42 0l-1.7-1.7a6.28 6.28 0 0 0-4.4-1.82Z"
                                                    data-name="Layer 54" fill="#b850f4" class="fill-101820"></path>
                                            </svg>
                                        </i>

                                        &nbsp;&nbsp;<span style="color: #fff;">{{ post.likes_count }}</span>
                                    </div>

                                    <div class="p-2">
                                        <i @click="$router.push('/post/' + post.id)" class="fa fa-comments"
                                            style="color:#ad3ef2; font-size:xx-large;" aria-hidden="true"></i>

                                        &nbsp;&nbsp;<span style="color: #fff;">{{ post.comments_count }}</span>
                                    </div>


                                    <div class="p-2">
                                        <i class="fa fa-share" style="color:#ad3ef2; font-size:xx-large;"
                                            aria-hidden="true"></i>

                                    </div>
                                </div>
                            </div>

                            <div class="p-2">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="p-0">
                                        <a class="btn btn-primary btn-tip">Send Tip</a>
                                    </div>
                                    <div class="p-2">
                                        <i @click="bookMarkPost(post.id, 'remove_bookmark', post)" class="fa fa-bookmark"
                                            aria-hidden="true" style="color:#ad3ef2; font-size:xx-large;"
                                            v-if="post.isBookMarked"></i>
                                        <i @click="bookMarkPost(post.id, 'bookmark', post)" class="fa fa-bookmark-o"
                                            aria-hidden="true" style="color:#ad3ef2; font-size:xx-large;" v-else></i>
                                        

                                    </div>
                                </div>

                            </div>

                        </div>
                        <h4 class="card-title">{{ post.personal_details.nick_name }}</h4>


                        <p class="card-text"><small class="text-muted">Posted:
                                {{ getDisplayDate(post.updated_at) }}</small></p>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { api_url, datumMixin } from '../../env';
import VideoPlayer from 'src/components/Video.vue';
import Story from './Story.vue';



export default {
    name: 'explore-page',
    layout: 'default',
    mixins: [datumMixin],
    components: {
        VideoPlayer,
        Story,
    },
    data() {
        return {
            videoOptions: {
                autoplay: true,
                controls: true,
                sources: [
                    {
                        src:
                            '/videos/sample_video.mp4',
                        type: 'video/mp4'
                    }
                ]
            },
            posts: [],
            post_id: 0,
            action: "",
            likeCount: 0

        };
    },
    methods: {
        getPosts() {
            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };
            this.$http.get(api_url + '/posts?type=2', { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                })
                .then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            response.posts.forEach(post => {
                                let personal_details = (post.personal_details) ? JSON.parse(post.personal_details) : null
                                post.personal_details = personal_details
                            });
                            this.posts = response.posts
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                },
                )

        },
        likeAndUnlikePosts(post_id, action, post) {

            var formdata = new FormData();

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };
            this.$http.post(api_url + '/posts/like?post_id=' + post_id + '&action=' + action, formdata, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                }).then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            if (action == "like") {
                                post.isLiked = true
                                post.likes_count++
                            }
                            else {
                                post.isLiked = false
                                post.likes_count--
                            }

                            console.log(response["message"])
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })

        },
        bookMarkPost(post_id, action, post) {

            var formdata = new FormData();

            let headers = {
                "Authorization": "Bearer " + localStorage.getItem('token'),
            };
            this.$http.post(api_url + '/posts/bookmark?post_id=' + post_id + '&action=' + action, formdata, { headers })
                .then(response => {
                    return response.json()
                }, response => {
                    return response
                }).then(response => {
                    if (response) {
                        if (response.status == 400) {
                            let bodyText = response.bodyText
                            bodyText = JSON.parse(bodyText)
                            this.alertNotification("top", "right", "danger", bodyText["message"])
                        } else if (response.status == "true") {
                            if (action == "like") {
                                post.isBookMarked = true
                            }
                            else {
                                post.isBookMarked = false
                            }
                            console.log(response["message"])
                        }
                        else {
                            this.alertNotification("top", "right", "danger", response.statusText)
                        }
                    }
                    else {
                        this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
                    }

                })

        },
    },
    created() {
        this.getPosts();
    }

};
</script>
<style>
.p-2 {
    cursor: pointer;
}

i {
    color: #A028F0;
}

@media screen and (min-width: 991px) {
    .tim-icons {
        font-size: 3em;
    }

    .tips-badge {
        font-size: 20px;
    }


}

@media screen and (min-width: 700px) and (max-width: 990px) {
    .tim-icons {
        font-size: 3em;
    }
}

@media screen and (max-width: 700px) {
    .tim-icons {
        font-size: 1.5em;
    }

    .btn-tip {
        font-size: 10px;
        padding: 10px;
    }
}</style>
  