<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div class="d-flex flex-wrap">
                <base-checkbox class="p-2" v-for="category in categories" :key="category.id"
                    v-model="selectedCategories[category.id]">
                    {{ category.name }}
                </base-checkbox>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { api_url, datumMixin } from '../../../env';
import { ImageUpload } from 'src/components/index';

export default {
    name: "EscortServices",
    components: { ImageUpload },
    data() {
        return {
            selectedCategories: {}, 
            categories: [],          
        };
    },
    mixins: [datumMixin],
    methods: {
        validate() {
            return this.$refs.form.validate().then(res => {
                if (!this.hasSelectedCategories()) {
                    this.alertNotification("top", "right", "danger", "Please select at least one category.");
                    return false;
                }
                this.$emit("on-validated", res);
                this.addServices();
                return res;
            });
        },

        // Check if at least one category is selected
        hasSelectedCategories() {
            return Object.values(this.selectedCategories).some(value => value === true);
        },

        getCategories() {
            let headers = { "Authorization": "Bearer " + localStorage.getItem('token') };
            this.$http.get(api_url + '/categories', { headers })
                .then(response => response.json())
                .then(response => {
                    if (response.status === "true") {
                        this.categories = response.categories;
                    } else {
                        this.alertNotification("top", "right", "danger", response.message || "Failed to load categories.");
                    }
                })
                .catch(error => {
                    this.alertNotification("top", "right", "danger", "Error loading categories: " + error.message);
                });
        },

        getUser() {
            let headers = { "Authorization": "Bearer " + localStorage.getItem('token') };
            this.$http.get(api_url + '/user', { headers })
                .then(response => response.json())
                .then(response => {
                    if (response.status === "true" && response.user.length > 0) {
                        try {

                            let services = JSON.parse(response.user[0].services);


                            if (services && services.categories) {
                                this.selectedCategories = services.categories.reduce((acc, id) => {
                                    acc[id] = true;
                                    return acc;
                                }, {});
                            } else {
                                this.alertNotification("top", "right", "danger", "No categories found in services.");
                            }
                        } catch (error) {
                            this.alertNotification("top", "right", "danger", "Error parsing user services: " + error.message);
                        }
                    } else {
                        this.alertNotification("top", "right", "danger", response.message || "Failed to load user services.");
                    }
                })
                .catch(error => {
                    this.alertNotification("top", "right", "danger", "Error loading user: " + error.message);
                });
        },

        addServices() {
            const selectedCategories = Object.keys(this.selectedCategories).filter(id => this.selectedCategories[id] === true);

            if (selectedCategories.length === 0) {
                this.alertNotification("top", "right", "danger", "Please select at least one category.");
                return;
            }

            let headers = { "Authorization": "Bearer " + localStorage.getItem('token') };
            let data = { categories: selectedCategories };

            this.$http.post(api_url + '/models/addServices', data, { headers })
                .then(response => response.json())
                .then(response => {
                    if (response.status === "true") {
                        this.alertNotification("top", "right", "success", response.message);
                    } else {
                        this.alertNotification("top", "right", "danger", response.message || "Failed to save services.");
                    }
                })
                .catch(error => {
                    this.alertNotification("top", "right", "danger", "Error saving services: " + error.message);
                });
        }
    },

    created() {
        this.getCategories();
        this.getUser();
    }
};
</script>
