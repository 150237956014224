<template>
  <div class="row">
    <div class="col-md-8 ml-auto mr-auto">
      <card class="card-subcategories card-plain">
        <template slot="header">
          <h4 class="card-title text-center mt-5">My Profile</h4>
          <br />
        </template>

        <tabs type="primary" tabContentClasses="tab-subcategories" square centered class="row">
          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-badge"></i>Personal Details
            </span>
            <ValidationObserver ref="form">
              <form @submit.prevent="addAccountDetails">
                <div>
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-6">
                      <label>First Name</label>
                      <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="firstName" placeholder="First Name"
                          addon-left-icon="tim-icons icon-single-02" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Middle Name</label>
                      <ValidationProvider name="middleName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="middleName" placeholder="Middle Name"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Last Name</label>
                      <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="lastName" placeholder="Last Name"
                          addon-left-icon="tim-icons icon-caps-small" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Email</label>
                      <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                        <base-input disabled required v-model="email" placeholder="Email"
                          addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>D.O.B</label>
                      <ValidationProvider name="dob" rules="required" v-slot="{ passed, failed, errors }">
                        <base-input :class="[{ 'has-success': passed }, { 'has-danger': failed }]" :error="errors[0]">
                          <el-date-picker type="date" placeholder="Date of birth" v-model="dob">
                          </el-date-picker>
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <label>Phone</label>
                      <ValidationProvider name="phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="phone" placeholder="Phone(Not shown to public)"
                          addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="text-right">
                      <base-button native-type="submit" type="primary">Save</base-button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </tab-pane>

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-bag-16"></i>Subscriptions
            </span>
            <div class="col-md-12">
              <card class="card-plain" body-classes="table-full-width">
                <el-table header-cell-class-name="table-transparent" header-row-class-name="table-transparent"
                  row-class-name="table-transparent" :data="tableData">
                  <el-table-column min-width="300" sortable align="left" header-align="left" label="Model Name"
                    property="modelName"></el-table-column>
                  <el-table-column min-width="300" sortable align="right" header-align="right" label="Subscription Date"
                    property="subscriptionDate"></el-table-column>
                  <el-table-column min-width="300" sortable align="right" header-align="right" label="Expiry Date"
                    property="expiryDate"></el-table-column>
                  <!-- <el-table-column min-width="150" sortable align="right" header-align="right" label="Salary"
                    property="salary"></el-table-column> -->
                </el-table>
              </card>
            </div>
          </tab-pane>

          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-money-coins "></i>Payment Details
            </span>
            <ValidationObserver ref="form">
              <form @submit.prevent="addAccountDetails">
                <div>
                  <div class="row justify-content-center lg-5">
                    <div class="col-md-12">
                      <label>Phone</label>
                      <ValidationProvider name="phone" rules="required|numeric" v-slot="{ passed, failed, errors }">
                        <base-input required v-model="phone" placeholder="Phone(Not shown to public)"
                          addon-left-icon="tim-icons icon-mobile" :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                      </ValidationProvider>
                    </div>
                    <div class="text-center">
                      <base-button native-type="submit" type="primary">Update Payment Details</base-button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </tab-pane>
          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-tap-02"></i>Likes
            </span>
            <div class="d-flex" style="flex-wrap: wrap;" v-if="!scrollViewLikes">

              <div class="datum-grid col-lg-3 col-md-2 col-3 mb-1" v-for="post in LikedPosts">
                <a @click="setScrollView(true, 'likes')">
                  <div class="card" style="cursor: pointer;">
                    <img slot="image" class="card-img-top" :src="post.path" alt="Card image cap">
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-6 ml-auto mr-auto" v-if="scrollViewLikes">

              <div v-for="post in  LikedPosts">
                <div v-if="post.media_type == 1">
                  <card class="mb-3">

                    <img slot="image" class="card-img-top" :src="post.path" alt="Card image cap">
                    <span class="card-text">{{ post.caption }}</span>


                    <div class="d-flex justify-content-between align-items-center">
                      <div class="p-2">
                        <div class="d-flex justify-content-between align-items-center">

                          <div class="p-2">

                            <i @click="likeAndUnlikePosts(post.id, 'remove_like', post)" v-if="post.isLiked">
                              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 26 26"
                                fill="#aa39f1">
                                <path
                                  d="M12 21.35l-1.45-1.32C5.4 16.07 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 7.57-8.55 11.54L12 21.35z" />
                              </svg>
                            </i>


                            <i @click="likeAndUnlikePosts(post.id, 'like', post)" v-else>
                              <svg viewBox="0 0 32 32" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M16 28.72a3 3 0 0 1-2.13-.88l-10.3-10.3a8.72 8.72 0 0 1-2.52-6.25 8.06 8.06 0 0 1 8.14-8A8.06 8.06 0 0 1 15 5.68l1 1 .82-.82a8.39 8.39 0 0 1 11-.89 8.25 8.25 0 0 1 .81 12.36l-10.5 10.51a3 3 0 0 1-2.13.88ZM9.15 5.28A6.12 6.12 0 0 0 4.89 7a6 6 0 0 0-1.84 4.33A6.72 6.72 0 0 0 5 16.13l10.3 10.3a1 1 0 0 0 1.42 0l10.51-10.52a6.25 6.25 0 0 0 1.77-4.8 6.18 6.18 0 0 0-2.43-4.55 6.37 6.37 0 0 0-8.37.71L16.71 8.8a1 1 0 0 1-1.42 0l-1.7-1.7a6.28 6.28 0 0 0-4.4-1.82Z"
                                  data-name="Layer 54" fill="#b850f4" class="fill-101820"></path>
                              </svg>
                            </i>

                            &nbsp;&nbsp;<span style="color: #fff;">{{ post.likes_count }}</span>
                          </div>

                          <div class="p-2">
                            <i @click="$router.push('/post/' + post.id)" class="fa fa-comments"
                              style="color:#ad3ef2; font-size:xx-large;" aria-hidden="true"></i>

                            &nbsp;&nbsp;<span style="color: #fff;">{{ post.comments_count }}</span>
                          </div>


                          <div class="p-2">
                            <i class="fa fa-share" style="color:#ad3ef2; font-size:xx-large;" aria-hidden="true"></i>

                          </div>
                        </div>
                      </div>

                      <div class="p-2">
                        <div class="d-flex justify-content-end align-items-center">
                          <div class="p-0">
                            <a class="btn btn-primary btn-tip">Send Tip</a>
                          </div>
                          <div class="p-2">
                            <i @click="bookMarkPost(post.id, 'remove_bookmark', post)" class="fa fa-bookmark"
                              aria-hidden="true" style="color:#ad3ef2; font-size:xx-large;" v-if="post.isBookMarked"></i>
                            <i @click="bookMarkPost(post.id, 'bookmark', post)" class="fa fa-bookmark-o"
                              aria-hidden="true" style="color:#ad3ef2; font-size:xx-large;" v-else></i>
                          </div>
                        </div>

                      </div>

                    </div>
                    <h4 class="card-title">{{ post.personal_details.nick_name }}</h4>


                    <p class="card-text"><small class="text-muted">Posted:
                        {{ getDisplayDate(post.updated_at) }}</small></p>
                  </card>
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-book-bookmark"></i>Bookmarks
            </span>
            <div class="d-flex" style="flex-wrap: wrap;" v-if="!scrollViewBookMarks">

              <div class="datum-grid col-lg-3 col-md-2 col-3 mb-1" v-for="post in BookmakedPosts">
                <a @click="setScrollView(true)">
                  <div class="card" style="cursor: pointer;">
                    <img slot="image" class="card-img-top" :src="post.path" alt="Card image cap">
                  </div>
                </a>
              </div>
            </div>
            <div class="col-md-6 ml-auto mr-auto" v-if="scrollViewBookMarks">
              <div v-for="post in  BookmakedPosts">
                <div v-if="post.media_type == 1">
                  <card class="mb-3">

                    <img slot="image" class="card-img-top" :src="post.path" alt="Card image cap">
                    <span class="card-text">{{ post.caption }}</span>


                    <div class="d-flex justify-content-between align-items-center">
                      <div class="p-2">
                        <div class="d-flex justify-content-between align-items-center">

                          <div class="p-2">

                            <i @click="likeAndUnlikePosts(post.id, 'remove_like', post)" v-if="post.isLiked">
                              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 26 26"
                                fill="#aa39f1">
                                <path
                                  d="M12 21.35l-1.45-1.32C5.4 16.07 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 7.57-8.55 11.54L12 21.35z" />
                              </svg>
                            </i>


                            <i @click="likeAndUnlikePosts(post.id, 'like', post)" v-else>
                              <svg viewBox="0 0 32 32" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M16 28.72a3 3 0 0 1-2.13-.88l-10.3-10.3a8.72 8.72 0 0 1-2.52-6.25 8.06 8.06 0 0 1 8.14-8A8.06 8.06 0 0 1 15 5.68l1 1 .82-.82a8.39 8.39 0 0 1 11-.89 8.25 8.25 0 0 1 .81 12.36l-10.5 10.51a3 3 0 0 1-2.13.88ZM9.15 5.28A6.12 6.12 0 0 0 4.89 7a6 6 0 0 0-1.84 4.33A6.72 6.72 0 0 0 5 16.13l10.3 10.3a1 1 0 0 0 1.42 0l10.51-10.52a6.25 6.25 0 0 0 1.77-4.8 6.18 6.18 0 0 0-2.43-4.55 6.37 6.37 0 0 0-8.37.71L16.71 8.8a1 1 0 0 1-1.42 0l-1.7-1.7a6.28 6.28 0 0 0-4.4-1.82Z"
                                  data-name="Layer 54" fill="#b850f4" class="fill-101820"></path>
                              </svg>
                            </i>

                            &nbsp;&nbsp;<span style="color: #fff;">{{ post.likes_count }}</span>
                          </div>

                          <div class="p-2">
                            <i @click="$router.push('/post/' + post.id)" class="fa fa-comments"
                              style="color:#ad3ef2; font-size:xx-large;" aria-hidden="true"></i>

                            &nbsp;&nbsp;<span style="color: #fff;">{{ post.comments_count }}</span>
                          </div>


                          <div class="p-2">
                            <i class="fa fa-share" style="color:#ad3ef2; font-size:xx-large;" aria-hidden="true"></i>

                          </div>
                        </div>
                      </div>

                      <div class="p-2">
                        <div class="d-flex justify-content-end align-items-center">
                          <div class="p-0">
                            <a class="btn btn-primary btn-tip">Send Tip</a>
                          </div>
                          <div class="p-2">
                            <i @click="bookMarkPost(post.id, 'remove_bookmark', post)" class="fa fa-bookmark"
                              aria-hidden="true" style="color:#ad3ef2; font-size:xx-large;" v-if="post.isBookMarked"></i>
                            <i @click="bookMarkPost(post.id, 'bookmark', post)" class="fa fa-bookmark-o"
                              aria-hidden="true" style="color:#ad3ef2; font-size:xx-large;" v-else></i>
                          </div>
                        </div>

                      </div>

                    </div>
                    <h4 class="card-title">{{ post.personal_details.nick_name }}</h4>


                    <p class="card-text"><small class="text-muted">Posted:
                        {{ getDisplayDate(post.updated_at) }}</small></p>
                  </card>
                </div>
              </div>
            </div>
          </tab-pane>
          <tab-pane>
            <span slot="label">
              <i class="tim-icons icon-coins"></i>Recent Payments
            </span>
            <div class="col-md-12">
              <card class="card-plain" body-classes="table-full-width">
                <el-table header-cell-class-name="table-transparent" header-row-class-name="table-transparent"
                  row-class-name="table-transparent" :data="tableData">
                  <el-table-column min-width="500" sortable label="Reference Number"
                    property="modelName"></el-table-column>
                  <!-- <el-table-column min-width="300" sortable label="Payment Date"
                    property="subscriptionDate"></el-table-column> -->

                  <el-table-column min-width="400" sortable align="right" header-align="right" label="Payment Date"
                    property="subscriptionDate"></el-table-column>
                </el-table>
              </card>
            </div>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>
</template>
<script>
import { extend, validate } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";
import { DatePicker } from 'element-ui';
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import { api_url, datumMixin } from '../../../env';
import { Table, TableColumn } from 'element-ui';



extend("email", email);
extend("required", required);
extend("numeric", numeric);


export default {
  name: 'subscribers',
  layout: 'default',
  mixins: [datumMixin],
  components: {
    [DatePicker.name]: DatePicker,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },


  data() {
    return {
      activeName: 'first',
      LikedPosts: [],
      BookmakedPosts: [],
      //Account Details & Payment Details
      firstName: '',
      lastName: '',
      middleName: '',
      phone: '',
      email: '',
      dob: '',
      scrollViewLikes: false,
      scrollViewBookMarks: false,
      tableData: [
        {
          id: 1,
          modelName: 'Candice',
          subscriptionDate: '01/02/2024',
          expiryDate: '01/03/2024'
        },
        {
          id: 2,
          modelName: 'Joan',
          subscriptionDate: '01/02/2024',
          expiryDate: '29/02/2024'
        },
      ]



    };
  },
  methods: {
    getAccountDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/user', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.user = response.user[0]
              this.firstName = response.user["first_name"]
              this.middleName = response.user["middle_name"]
              this.lastName = response.user["last_name"]
              this.email = response.user["email"]
              let personaDetails = JSON.parse(response.user.personal_details)
              this.phone = personaDetails.phone_number
              this.dob = personaDetails.dob
              // let paymentDetails = JSON.parse(response.user.payment_details)
              // this.paymentNumber = paymentDetails.payment_number
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    getLikedPosts() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
      };
      this.$http.get(api_url + '/user/interactions/likes', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.posts.forEach(post => {
                let personal_details = (post.personal_details) ? JSON.parse(post.personal_details) : null
                post.personal_details = personal_details
              });
              this.LikedPosts = response.posts
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )

    },
    getBookmarks() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
      };
      this.$http.get(api_url + '/user/interactions/bookmarks', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              response.posts.forEach(post => {
                let personal_details = (post.personal_details) ? JSON.parse(post.personal_details) : null
                post.personal_details = personal_details
              });
              this.BookmakedPosts = response.posts
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )

    },
    getRecentPayments() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),

      };
      this.$http.get(api_url + '/agents', { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {

              //steps
              //1. Get all the data and prep it
              response.agents.forEach(agent => {
                let location_details = (agent.location_details) ? JSON.parse(agent.location_details) : null
                let agency_details = (agent.agency_details) ? JSON.parse(agent.agency_details) : null
                let payment_details = (agent.payment_details) ? JSON.parse(agent.payment_details) : null

                //2 . Prep it
                agent.location_details = location_details
                agent.agency_details = agency_details
                agent.payment_details = payment_details



              });


              //3. Assign it to this.model
              this.agencies = response.agents


            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    addAccountDetails() {
      let headers = {
        "Authorization": "Bearer " + localStorage.getItem('token'),
        "Content-Type": "multipart/form-data",

      };
      var formdata = new FormData();
      formdata.append("first_name", this.firstName);
      formdata.append("last_name", this.lastName);
      formdata.append("middle_name", this.middleName);
      formdata.append("dob", this.dob);
      formdata.append("email", this.email);
      formdata.append("phone_number", this.phone);


      this.$http.post(api_url + '/user/save', formdata, { headers })
        .then(response => {
          return response.json()
        }, response => {
          return response
        })
        .then(response => {
          if (response) {
            if (response.status == 400) {
              let bodyText = response.bodyText
              bodyText = JSON.parse(bodyText)
              this.alertNotification("top", "right", "danger", bodyText["message"])
            } else if (response.status == "true") {
              this.alertNotification("top", "right", "success", response.message)
            }
            else {
              this.alertNotification("top", "right", "danger", response.statusText)
            }
          }
          else {
            this.alertNotification("top", "right", "danger", "We are unable to fetch info from the server. Try reloading the page")
          }

        },
        )
    },
    setScrollView(action, tab) {
      if (tab == "likes") {
        this.scrollViewLikes = action;
      } else {
        this.scrollViewBookMarks = action;
      }
    }

  },
  created() {
    this.getAccountDetails();
    this.getLikedPosts();
    this.getBookmarks();
  }
};
</script>
<style lang="scss">
.tim-icons {
  color: white;
}

.table-transparent {
  background-color: transparent !important;
}</style>